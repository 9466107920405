import { Link, useLocation } from "react-router-dom";
import "./Header.scss";
import logo from "../../assets/images/logo7.png";
import { useEffect, useState } from "react";
function Header() {
  const location = useLocation();
const [currentPage, setCurrentPage] = useState("");

useEffect(() => {
  setCurrentPage(location.pathname);
}, [location]);

  return (
    <>
      <section className="header">
        <div className="header__wrapper">
          <Link to="/">
            <img className="header__logo" src={logo} alt="jordan fish logo" />
          </Link>
          <div className="header__icons-wrapper">
            <Link to="/about">
            <div className={currentPage === "/about" ? "header__about active" : "header__about"}>About</div>
            </Link>
            <Link to="/projects">
            <div className={currentPage === "/projects" ? "header__projects active" : "header__projects"}>
Projects</div>
            </Link>
            <Link to="/contact">
            <div className={currentPage === "/contact" ? "header__contact active" : "header__contact"}>Contact</div>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}
export default Header;
