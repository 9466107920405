import './CTA.scss'
function CTA() {
  return (
    <>
      <section className="CTA">
        <div className="CTA__wrapper">
        <h1 className="CTA__title">Jordan Fish</h1>
        <h2 className="CTA__text">
          Full stack developer trained in inquiry-based teaching strategies and a
          proven track record of creating successful online educational content
        </h2>
      
        </div>
        <div className="CTA__stats-wrapper">
        <span className="CTA__item">BrainStation software engineering alumni</span>
        <span className="CTA__item">Nominated for two Vancouver Tourism awards </span>
        <span className="CTA__item">Taught hundreds of students K - Gr.12</span>
        </div>
      </section>
    </>
  );
}
export default CTA;
