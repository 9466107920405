import './App.scss';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Homepage from "../src/pages/Homepage/Homepage";
import NotFound from "../src/pages/NotFound/NotFound";
import About from './pages/About/About';
import Projects from './pages/Projects/Projects';
import Contact from './pages/Contact/Contact';
import Footer from './components/Footer/Footer';


function App() {
  return (
  <>
  <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </BrowserRouter>
  </>
  );
}

export default App;
