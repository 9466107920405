import "./NotFound.scss";
import error from "../../assets/images/error-404.png";
import logo from "../../assets/images/logo7.png";
import { Link } from "react-router-dom";
function NotFound() {
  return (
    <>
      <section className="NF">
        <Link to="/">
          <img className="NF__logo" src={logo} alt="jordan fish logo" />
        </Link>
        <h1 className="NF__text">Hmm...can't find that page...sorry!</h1>
        <img className="NF__img" src={error} alt="error msg" />
      </section>
    </>
  );
}

export default NotFound;
