import "./Contact.scss";
import Header from "../../components/Header/Header";
import { useForm, ValidationError } from "@formspree/react";
import github from "../../assets/images/github.png";
import linkedin from "../../assets/images/linkedin.png";
import Homepage from "../Homepage/Homepage";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Contact() {
  const [state, handleSubmit] = useForm("mqkobkpl");

  if (state.succeeded) {
    console.log("clicked");
    toast.success("I'll respond soon!");
    return <Homepage />;
  }

  return (
    <>
      <section className="contact">
        <Header />

        <div className="contact__wrapper">
          <div className="contact__icon-wrapper">
            <a href="https://github.com/Jorfishdan" target="_blank"  rel="noreferrer"> 
              {" "}
              <img src={github} alt="github logo" className="contact__github" />
            </a>
            <a href="https://www.linkedin.com/in/jordan-fish/" target="_blank"  rel="noreferrer">
              <img
                src={linkedin}
                alt="linkedin logo"
                className="contact__linkedin"
              />
            </a>
          </div>
          <form
            className="contact__form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="contact__email-wrapper">
              <label htmlFor="email" className="contact__email-title">
                Your email address:
              </label>
              <input
                className="contact__email"
                id="email"
                type="email"
                name="email"
              />
            </div>
            <ValidationError
              prefix="Email"
              field="email"
              errors={state.errors}
            />
            <span className="contact__prompt">Send me a message:</span>
            <textarea
              id="message"
              type="text"
              name="message"
              className="contact__msg"
            />
            <ValidationError
              prefix="Message"
              field="message"
              errors={state.errors}
            />
            <button
              className="contact__submit"
              type="submit"
              disabled={state.submitting}
            >
              Submit
            </button>
          </form>
        </div>
      </section>
    </>
  );
}

export default Contact;
