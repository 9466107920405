import "./Homepage.scss";
import Header from "../../components/Header/Header";
import CTA from "../../components/CTA/CTA";
import Hero from "../../components/Hero/Hero";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Homepage() {
  return (
    <>
    <Header />
      <div className="homepage">
        
        <ToastContainer />
        <div className="homepage__main-comps">
          <div className="homepage__hero-wrapper">
            <CTA />
            <Hero />
          </div>
        </div>
      </div>
    </>
  );
}

export default Homepage;
