import "./Footer.scss";
import copyright from "../../assets/images/copyright.png";
function Footer() {
  return (
    <>
      <section className="footer">
        <div className="footer__text-wrapper">
          <img className="footer__img" src={copyright} alt="copyright logo" />
          <h3 className="footer__text">Jordan Fish</h3>
        </div>
      </section>
    </>
  );
}
export default Footer;
