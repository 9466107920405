import "./About.scss";
import avatar from "../../assets/images/avatar.png";
import Header from "../../components/Header/Header";
import { useState } from "react";
function About() {
  const [question, setQuestion] = useState(false);
  const [questionTwo, setQuestionTwo] = useState(false);
  const [questionThree, setQuestionThree] = useState(false);

  function handleClick1(setQuestion) {
    setQuestion((prevState) => !prevState);
  }
  function handleClick2(setQuestionTwo) {
    setQuestionTwo((prevState) => !prevState);
  }
  function handleClick3(setQuestionThree) {
    setQuestionThree((prevState) => !prevState);
  }

  return (
    <>
      <section className="about">
        <Header />
        <div className="about__mega-wrapper">
          <div className="about__wrapper">
            <img className="about__img" src={avatar} alt="profile of jordan" />
            <p className="about__text">
              Hey, I'm Jordan (frequently nicknamed "Fish"), an accomplished
              junior developer with a passion for creating innovative solutions
              to complex problems for edTech and climate tech. With a background
              in{" "}
              <span className="about__highlight">
                {" "}
                management, education coordination, and program development
              </span>
              , I bring a unique perspective and a strong set of skills to any
              team
              <p className="about__text2">
                As a Manager at The Blue Marble Academy, I was quickly promoted
                after just three months in the coordinator role due to my
                outstanding performance. I recruited and{" "}
                <span className="about__highlight">
                  {" "}
                  trained nine new employees to be successful tutors and
                  collaborative team members
                </span>
                , and I've led the curriculum building for five educational
                programs at Ocean Wise
              </p>{" "}
              Along with my management skills, I'm also skilled in software
              development, having taken the lead in two BrainStation projects
              where I developed innovative solutions for classroom management
              and loyalty program engagement. With my ability to{" "}
              <span className="about__highlight">
                collaborate effectively, think critically, and write clean and
                efficient code
              </span>
              , I am confident that I can be a valuable asset to a software
              engineering team
            </p>
          </div>
          <article className="about__game">
            <h3 className="about__game-header">Guess The Answer</h3>
            <div className="about__gameQ">
              <div className="about__game-Q1">
                <span
                  className={`about__q ${question && "correct1"}`}
                  onClick={() => handleClick1(setQuestion)}
                >
                  I'm the youngest of eight
                </span>
                <span
                  className={`about__q ${question && "incorrect1"}`}
                  onClick={() => handleClick1(setQuestion)}
                >
                  {" "}
                  I've been to every contintent{" "}
                </span>
                <span
                  className={`about__q ${question && "incorrect1"}`}
                  onClick={() => handleClick1(setQuestion)}
                >
                  I have a pet tarantula
                </span>
              </div>
              <div className="about__game-Q2">
                <span
                  className={`about__q ${questionTwo && "correct2"}`}
                  onClick={() => handleClick2(setQuestionTwo)}
                >
                  I've worked at an Aquarium as Ms.Fish{" "}
                </span>
                <span
                  className={`about__q ${questionTwo && "incorrect2"}`}
                  onClick={() => handleClick2(setQuestionTwo)}
                >
                  I've gotten a book signed by Robert Downey Jr.{" "}
                </span>
                <span
                  className={`about__q ${questionTwo && "incorrect2"}`}
                  onClick={() => handleClick2(setQuestionTwo)}
                >
                  I've won 3 crossfit competitions{" "}
                </span>
              </div>

              <div className="about__game-Q3">
                <span
                  className={`about__q ${questionThree && "incorrect3"}`}
                  onClick={() => handleClick3(setQuestionThree)}
                >
                  {" "}
                  I have a star named after me
                </span>
                <span
                  className={`about__q ${questionThree && "correct3"}`}
                  onClick={() => handleClick3(setQuestionThree)}
                >
                  {" "}
                  I frequently get gifted Frida Kahlo items{" "}
                </span>
                <span
                  className={`about__q ${questionThree && "incorrect3"}`}
                  onClick={() => handleClick3(setQuestionThree)}
                >
                  I've never eaten meat
                </span>
              </div>
            </div>
          </article>
        </div>
      </section>
    </>
  );
}
export default About;
