import './Hero.scss';
import display from "../../assets/images/display2.png";
function Hero() {
    return(
    <>
    <section className="hero">
        <div className="hero__wrapper">
       <div className="hero__circle">
        <img className="hero__image2" src={display} alt="promo" />
        </div>
        </div>
    </section>
    </>)

}
export default Hero;