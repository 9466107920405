import "./Projects.scss";
import Header from "../../components/Header/Header";
import acPic from "../../assets/images/ac.png";
import bmPic from "../../assets/images/BM.png";
import ffPic from "../../assets/images/fundfinder.png";
import tpPic from "../../assets/images/pets-home2.png";


function Projects() {
  return (
    <>
      <section className="projects">
        <Header />
        <article className="projects__avanade">
          <div className="projects__avanade-wrapper">
            <h3 className="projects__info">
              Avanade Hackathon
            </h3>
            <p className="projects__description">
              For this 48 hour hackathon, I collaborated with two developers to
              build an app catered to women and minorities who are transitioning
              into the tech industry by providing easy access to financial tools
              and resources
            </p>
            <p className="projects__tech">
              Tech stack used: HTML, Sass, Javascript, React, Node.js, APIs and
              Express
            </p>
            <div className="projects__btn-wrapper">
            <a href="https://github.com/Jorfishdan/FundFinder-client" target="_blank"  rel="noreferrer">
              <button className="projects__repo">Repo</button>
            </a>
            <a href="https://youtu.be/6oJq9f9QBWw" target="_blank"  rel="noreferrer">
              <button className="projects__demo">Demo</button>
            </a>
            </div>
          </div>
       <img src={ffPic} alt="fundfinder preview" className="projects__vid" />
        </article>

        <article className="projects__capstone">
          <div className="projects__capstone-wrapper">
            <h3 className="projects__info">BrainStation Capstone</h3>
            <p className="projects__description">
              I utilized what I learned during the software engineering bootcamp
              program to design, document, build, and present a web application
              for my capstone project. My capstone, called Teacher's Pet,
              supports elementary educators with effective and fun class
              management games.
              <br></br>
              <br></br>
              It is scheduled to be beta tested in the Richmond school district
              in March 2023
            </p>
            <p className="projects__tech">
              Tech stack used: HTML, Sass, APIs, Javascript, React, Node.js and
              Express
            </p>
            <div className="projects__btn-wrapper">
            <a href="https://github.com/Jorfishdan/capstone" target="_blank"  rel="noreferrer">
              <button className="projects__repo">Repo</button>
            </a>
            <a href="https://youtu.be/o77SKPKBIz4" target="_blank"  rel="noreferrer">
              <button className="projects__demo">Demo</button>
            </a>
            </div>
          </div>
          <img src={tpPic} alt="fundfinder preview" className="projects__vid" />
        </article>

        <article className="projects__AC">
          <div className="projects__AC-wrapper">
            <h3 className="projects__info">
              Air Canada 24hr Hackathon
            </h3>
            <p className="projects__description">
              Participated in a 24 hour hackathon to create a seamless design
              solution for Air Canada to increase engagement. I collaborated
              with two fellow developers, a UX designer and data scientist to
              succesfully complete our prototype within the tight deadline
            </p>
            <p className="projects__tech">
              Tech stack used: HTML, Sass, Javascript & React
            </p>
            <div className="projects__btn-wrapper">
            <a href="https://github.com/Jorfishdan/air-canada" target="_blank"  rel="noreferrer">
              <button className="projects__repo">Repo</button>
            </a>
            <a href="https://youtu.be/cply_qc21B0" target="_blank"  rel="noreferrer">
              <button className="projects__demo">Demo</button>
            </a>
            </div>
          </div>
          <img src={acPic} alt="fundfinder preview" className="projects__vid" />
        </article>
        <article className="projects__BM">
          <div className="projects__BM-wrapper">
            <h3 className="projects__info">The Blue Marble Academy Website</h3>
            <p className="projects__description">
              Led revamp of company website both in design and functionality to
              improve the user experience and effectively promote services
            </p>
            <p className="projects__tech">Tech stack used: Squarespace</p>
            <a href="https://youtu.be/33uuPWRBA28" target="_blank"  rel="noreferrer">
              <button className="projects__demo">Demo</button>
            </a>
          </div>
          <img src={bmPic} alt="fundfinder preview" className="projects__vid" />
        </article>
      </section>
    </>
  );
}
export default Projects;
